import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const Wysiwyg = styled(Box)`
  p {
    font-size: ${themeGet("fontSizes.default")};
    margin: 0 0 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0 0 0.5em;
  }

  a {
    text-decoration: underline;
    color: ${themeGet("colors.white")};
    &:hover {
      text-decoration: none;
    }
  }

  h1 {
    font-size: ${themeGet("fontSizes.h1")};
  }
  h2 {
    font-size: ${themeGet("fontSizes.h2")};
  }
  h3 {
    font-size: ${themeGet("fontSizes.h3")};
  }
  h4 {
    font-size: ${themeGet("fontSizes.h4")};
  }
`;

Wysiwyg.displayName = "Wysiwyg";

Wysiwyg.defaultProps = {
  color: "textGrey",
  lineHeight: 3,
};

export { Wysiwyg };
