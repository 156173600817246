import React from "react";

export const Lectures = props => (
  <svg
    width="167"
    height="167"
    viewBox="0 0 167 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.5 43.2243H39.8789V104.224H126.5V43.2243ZM35.8789 39.2243V108.224H130.5V39.2243H35.8789Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6895 83.9118H104.086V84.9118H45.6895V83.9118Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6895 93.2243H104.086V94.2243H45.6895V93.2243Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6895 74.5993H104.086V75.5993H45.6895V74.5993Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6895 65.2868H104.086V66.2868H45.6895V65.2868Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.6895 52.9743L120.689 52.9743V56.9743L45.6895 56.9743V52.9743Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.1895 127.724C70.1895 121.097 64.8169 115.724 58.1895 115.724C51.562 115.724 46.1895 121.097 46.1895 127.724L45.1895 127.724C45.1895 120.545 51.0098 114.724 58.1895 114.724C65.3692 114.724 71.1895 120.545 71.1895 127.724L70.1895 127.724Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M95.1895 127.724C95.1895 121.097 89.8169 115.724 83.1895 115.724C76.562 115.724 71.1895 121.097 71.1895 127.724L70.1895 127.724C70.1895 120.545 76.0098 114.724 83.1895 114.724C90.3692 114.724 96.1895 120.545 96.1895 127.724L95.1895 127.724Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.189 127.724C120.189 121.097 114.817 115.724 108.189 115.724C101.562 115.724 96.1895 121.097 96.1895 127.724L95.1895 127.724C95.1895 120.545 101.01 114.724 108.189 114.724C115.369 114.724 121.189 120.545 121.189 127.724L120.189 127.724Z"
    />
  </svg>
);
