import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Box } from "../Grid";

const ProductsDeploy = styled(Box)`
  position: relative;
  display: block;
  padding-bottom: 25px;
  font-size: ${themeGet("fontSizes.h4")};
  font-weight: ${themeGet("fontWeights.medium")};
  color: ${themeGet("colors.darkGreen")};
  &.last {
    &:after {
      background-color: ${themeGet("colors.white")};
    }
  }
  &:after {
    display: block;
    content: "";
    position: absolute;
    left: 21px;
    top: 42px;
    bottom: 0;
    width: 1px;
    background-color: ${themeGet("colors.lightTitaniumGrey")};
  }
  & > span {
    z-index: 5;
    position: absolute;
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 100px;
    border: 1px solid ${themeGet("colors.lightTitaniumGrey")};
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  div {
    padding-left: 60px;
    padding-top: 5px;
  }
`;

export { ProductsDeploy };
