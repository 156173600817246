import styled from "styled-components";

import { Box } from "../Grid";
import themeGet from "@styled-system/theme-get";

const BreadcrumbItem = styled(Box)`
  position: relative;
  padding-right: 8px;
  margin-right: 8px;
  &:after {
    position: absolute;
    top: 50%;
    right: -4px;
    content: "}";
    font-size: 14px;
    transform: translateY(-50%);
    color: ${themeGet("colors.white")};
  }
  a {
    font-size: 14px;
    text-decoration: underline;
    color: ${themeGet("colors.lightGreen")};
    cursor: pointer;
    &:hover {
      text-decoration: none;
    }
  }
`;

BreadcrumbItem.defaultProps = {};

export { BreadcrumbItem };
