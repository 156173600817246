import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";

import { Box, Container, Flex, Wrapper } from "../style/Grid";
import { Heading } from "../style/Heading";
import { Text } from "../style/Text";
import { Wysiwyg } from "../style/Wysiwyg";
import { ProductsDeploy } from "../style/Products";
import { Image } from "../style/Image";
import { BreadcrumbItem, BreadcrumbWrapper } from "../style/Breadcrumb";
import HeaderInner from "../components/HeaderInner";
import Meta from "../components/Meta";
import { parseContent } from "../utils/lang";

export default ({
  data,
  mobileMenuOpen,
  toggleMobileMenu,
  pageContext: { translations, links, lang, currentSlug },
}) => {
  const { frontmatter, html } = parseContent(data.markdownRemark, lang);

  return (
    <Fragment>
      <Meta
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
      />
      <Wrapper bg="darkGreen" position="relative" zIndex={1000}>
        <HeaderInner
          mobileMenuOpen={mobileMenuOpen}
          toggleMobileMenu={toggleMobileMenu}
          translations={translations}
          links={links}
          lang={lang}
          slug={currentSlug}
        />
      </Wrapper>

      <Wrapper bg="darkGreen">
        <Container
          pt={[4]}
          pb={[5, null, null, 80]}
          position="relative"
          minHeight={400}
        >
          <BreadcrumbWrapper>
            <BreadcrumbItem>
              <Text as={Link} to={links.hp}>
		  {translations.menuHome}
              </Text>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Text as={Link} to={links.products.overview}>
                  {translations.menuProductsAndServices}
              </Text>
            </BreadcrumbItem>
          </BreadcrumbWrapper>

          <Box position="relative" zIndex="10">
            <Heading
              as="h1"
              fontSize={["h2", "h1"]}
              color="white"
              mb={4}
              fontWeight="medium"
            >
              {frontmatter.title}
            </Heading>

            <Text
              fontSize={["h3", "h2"]}
              color="cyberGreen"
              fontWeight="medium"
              maxWidth={600}
              dangerouslySetInnerHTML={{ __html: frontmatter.subtitle }}
            />
          </Box>

          {frontmatter.image && (
            <Image
              src={`/${frontmatter.image.src}`}
              position={["relative", null, "absolute"]}
              width={["100%", "auto"]}
              bottom={frontmatter.image.position.bottom}
              right={frontmatter.image.position.right}
              zIndex={5}
              alt={frontmatter.title}
            />
          )}
        </Container>
      </Wrapper>

      <Wrapper>
        <Container py={[4, null, null, 5]}>
          <Box maxWidth={800}>
            <Wysiwyg dangerouslySetInnerHTML={{ __html: html }} />
          </Box>

          {frontmatter.listOne && (
            <Fragment>
              <Heading as="h2" fontSize="h2" mt={4} mb={4} fontWeight="medium">
                {frontmatter.listOne.name}
              </Heading>

              <Flex justifyContent="center" flexWrap="wrap" mx={"-10px"}>
                {frontmatter.listOne.list.map((item, index) => (
                  <Flex
                    width={[
                      "100%",
                      "calc(50% - 20px)",
                      null,
                      "calc(33.33333333% - 20px)",
                    ]}
                    bg="lightTitaniumGrey30"
                    textAlign="center"
                    key={index}
                    alignItems="center"
                    mx={["10px"]}
                    mb={20}
                    px={[3, null, null, 5]}
                    py={[3, null, null, 5]}
                  >
                    <Text fontWeight="medium" fontSize="h4">
                      <Image
                        src={require("../assets/product-check.svg")}
                        display="block"
                        margin="0 auto 20px"
                        alt=""
                      />
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Fragment>
          )}

          {frontmatter.listTwo && (
            <Fragment>
              <Heading as="h2" fontSize="h2" mt={4} mb={4} fontWeight="medium">
                {frontmatter.listTwo.name}
              </Heading>

              <Flex justifyContent="flex-start" flexDirection="column">
                {frontmatter.listTwo.list.map((item, index) => (
                  <Flex
                    bg="lightTitaniumGrey30"
                    textAlign={["center", "left"]}
                    key={index}
                    alignItems="center"
                    flexDirection={["column", "row"]}
                    mb={20}
                    px={[3, null, null, 5]}
                    py={3}
                  >
                    <Image
                      src={require("../assets/product-check.svg")}
                      mr={3}
                      mb={[20, 0]}
                      alt=""
                    />
                    <Text fontWeight="medium" fontSize="h4">
                      {item}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Fragment>
          )}

          {frontmatter.listThree && (
            <Fragment>
              <Heading as="h2" fontSize="h2" mt={4} mb={4} fontWeight="medium">
                {frontmatter.listThree.name}
              </Heading>

              <Flex flexDirection="column">
                {frontmatter.listThree.list.map((item, index) => (
                  <ProductsDeploy
                    key={index}
                    className={
                      frontmatter.listThree.list.length === index + 1 && "last"
                    }
                  >
                    <span>
                      <span>{index + 1}.</span>
                    </span>
                    <div>{item}</div>
                  </ProductsDeploy>
                ))}
              </Flex>
            </Fragment>
          )}
        </Container>
      </Wrapper>
    </Fragment>
  );
};
export const pageQuery = graphql`
  query PRODUCT_DETAIL_QUERY($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        slug_cz

        title
        title_cz

        meta {
          title
          description
        }
        meta_cz {
          title
          description
        }

        subtitle
        subtitle_cz

        listOne {
          name
          list
        }
        listOne_cz {
          name
          list
        }

        listTwo {
          name
          list
        }
        listTwo_cz {
          name
          list
        }

        listThree {
          name
          list
        }
        listThree_cz {
          name
          list
        }
        image {
          position {
            bottom
            right
          }
          src
        }
      }
      html
    }
  }
`;
