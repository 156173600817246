import React from "react";

export const Consulting = props => (
  <svg
    width="167"
    height="167"
    viewBox="0 0 167 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.7781 112.676H114C120.627 112.676 126 107.304 126 100.676V61.6764C126 55.049 120.627 49.6764 114 49.6764H53C46.3726 49.6764 41 55.049 41 61.6764V100.708C41 107.326 46.3581 112.695 52.9763 112.708L62.6444 112.727L79.6828 126.207L79.7781 112.676ZM83.625 134.426L61.25 116.724L52.9684 116.708C44.1442 116.691 37 109.532 37 100.708V61.6764C37 52.8398 44.1634 45.6764 53 45.6764H114C122.837 45.6764 130 52.8398 130 61.6764V100.676C130 109.513 122.837 116.676 114 116.676H83.75L83.625 134.426Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.3295 61.1918C83.4397 61.1518 83.5603 61.1518 83.6705 61.1918L98.9205 66.7226C99.1183 66.7943 99.25 66.9822 99.25 67.1926V80.7243C99.25 86.6663 95.2966 91.5588 91.4543 94.9209C89.5226 96.6111 87.5932 97.9373 86.1478 98.8407C85.4246 99.2927 84.8212 99.6397 84.3974 99.8743C84.1855 99.9916 84.0185 100.081 83.9037 100.141C83.8463 100.171 83.802 100.194 83.7717 100.21L83.7368 100.227L83.7274 100.232L83.7248 100.233L83.724 100.234C83.7238 100.234 83.7236 100.234 83.5 99.7868C83.2764 100.234 83.2762 100.234 83.276 100.234L83.2752 100.233L83.2726 100.232L83.2632 100.227L83.2283 100.21C83.198 100.194 83.1537 100.171 83.0963 100.141C82.9815 100.081 82.8145 99.9916 82.6026 99.8743C82.1788 99.6397 81.5754 99.2927 80.8522 98.8407C79.4068 97.9373 77.4774 96.6111 75.5457 94.9209C71.7034 91.5588 67.75 86.6663 67.75 80.7243V67.1926C67.75 66.9822 67.8817 66.7943 68.0795 66.7226L83.3295 61.1918ZM83.5 99.7868L83.2764 100.234C83.4172 100.304 83.5828 100.304 83.7236 100.234L83.5 99.7868ZM83.5 99.2234C83.6045 99.1681 83.7436 99.0932 83.9131 98.9994C84.3234 98.7723 84.9114 98.4342 85.6178 97.9927C87.0318 97.109 88.9149 95.814 90.7957 94.1683C94.5784 90.8585 98.25 86.2198 98.25 80.7243V67.5431L83.5 62.1937L68.75 67.5431V80.7243C68.75 86.2198 72.4216 90.8585 76.2043 94.1683C78.0851 95.814 79.9682 97.109 81.3822 97.9927C82.0886 98.4342 82.6766 98.7723 83.0869 98.9994C83.2564 99.0932 83.3955 99.1681 83.5 99.2234Z"
    />
    <path d="M89.491 95.2573C89.1748 95.5092 88.8617 95.7507 88.554 95.9812L83.3294 94.0863C83.0698 93.9922 82.9357 93.7054 83.0299 93.4458C83.124 93.1862 83.4108 93.0521 83.6704 93.1463L89.491 95.2573Z" />
    <path d="M91.8606 93.1962C91.5864 93.4576 91.3098 93.7121 91.0322 93.9595L83.3294 91.1659C83.0698 91.0717 82.9357 90.785 83.0299 90.5254C83.124 90.2658 83.4108 90.1317 83.6704 90.2258L91.8606 93.1962Z" />
    <path d="M93.9221 91.0234C93.689 91.2973 93.4507 91.5656 93.2083 91.8283L83.3294 88.2454C83.0698 88.1513 82.9357 87.8645 83.0299 87.6049C83.124 87.3453 83.4108 87.2112 83.6704 87.3054L93.9221 91.0234Z" />
    <path d="M95.6475 88.7287C95.4589 89.0171 95.2629 89.3007 95.0604 89.5796L83.3294 85.325C83.0698 85.2308 82.9357 84.9441 83.0299 84.6845C83.124 84.4249 83.4108 84.2908 83.6704 84.3849L95.6475 88.7287Z" />
    <path d="M96.9922 86.296C96.8542 86.6013 96.7065 86.9025 96.5501 87.1994L83.3294 82.4045C83.0698 82.3104 82.9357 82.0236 83.0299 81.764C83.124 81.5044 83.4108 81.3703 83.6704 81.4645L96.9922 86.296Z" />
    <path d="M97.8911 83.7015C97.8126 84.0272 97.7221 84.3491 97.6205 84.6671L83.3294 79.4841C83.0698 79.39 82.9357 79.1032 83.0299 78.8436C83.124 78.584 83.4108 78.4499 83.6704 78.544L97.8911 83.7015Z" />
    <path d="M98.2485 80.9107C98.2432 81.2616 98.223 81.609 98.1888 81.9528L83.3294 76.5637C83.0698 76.4695 82.9357 76.1827 83.0299 75.9231C83.124 75.6635 83.4108 75.5294 83.6704 75.6236L98.2485 80.9107Z" />
    <path d="M98.2499 77.9908V79.0545L83.3294 73.6432C83.0698 73.5491 82.9357 73.2623 83.0299 73.0027C83.124 72.7431 83.4108 72.609 83.6704 72.7031L98.2499 77.9908Z" />
    <path d="M98.2499 75.0703V76.134L83.3294 70.7228C83.0698 70.6286 82.9357 70.3418 83.0299 70.0822C83.124 69.8226 83.4108 69.6885 83.6704 69.7827L98.2499 75.0703Z" />
    <path d="M98.2499 72.1499V73.2136L83.3294 67.8023C83.0698 67.7082 82.9357 67.4214 83.0299 67.1618C83.124 66.9022 83.4108 66.7681 83.6704 66.8622L98.2499 72.1499Z" />
    <path d="M98.2499 69.2294V70.2932L83.3294 64.8819C83.0698 64.7877 82.9357 64.5009 83.0299 64.2413C83.124 63.9817 83.4108 63.8476 83.6704 63.9418L98.2499 69.2294Z" />
    <path d="M85.7752 97.8938L83.3294 97.0068C83.0698 96.9126 82.9357 96.6259 83.0299 96.3663C83.124 96.1067 83.4108 95.9726 83.6704 96.0667L86.8235 97.2103C86.4545 97.4586 86.1032 97.6867 85.7752 97.8938Z" />
  </svg>
);
