import React from "react";

export const M2m = props => (
  <svg
    width="191"
    height="167"
    viewBox="0 0 191 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.875 67.1618C25.875 62.1912 29.9044 58.1618 34.875 58.1618H156.5C161.471 58.1618 165.5 62.1912 165.5 67.1618C165.5 68.2664 164.605 69.1618 163.5 69.1618C162.395 69.1618 161.5 68.2664 161.5 67.1618C161.5 64.4004 159.261 62.1618 156.5 62.1618H34.875C32.1136 62.1618 29.875 64.4004 29.875 67.1618C29.875 68.2664 28.9796 69.1618 27.875 69.1618C26.7704 69.1618 25.875 68.2664 25.875 67.1618Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M165.5 99.7868C165.5 104.757 161.471 108.787 156.5 108.787L34.875 108.787C29.9044 108.787 25.875 104.757 25.875 99.7868C25.875 98.6822 26.7704 97.7868 27.875 97.7868C28.9796 97.7868 29.875 98.6822 29.875 99.7868C29.875 102.548 32.1136 104.787 34.875 104.787L156.5 104.787C159.261 104.787 161.5 102.548 161.5 99.7868C161.5 98.6822 162.395 97.7868 163.5 97.7868C164.605 97.7868 165.5 98.6822 165.5 99.7868Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M178 94.9743C184.351 94.9743 189.5 89.8256 189.5 83.4743C189.5 77.123 184.351 71.9743 178 71.9743C171.649 71.9743 166.5 77.123 166.5 83.4743C166.5 89.8256 171.649 94.9743 178 94.9743ZM178 95.9743C184.904 95.9743 190.5 90.3779 190.5 83.4743C190.5 76.5707 184.904 70.9743 178 70.9743C171.096 70.9743 165.5 76.5707 165.5 83.4743C165.5 90.3779 171.096 95.9743 178 95.9743Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.5 83.4743L166 83.4743V84.4743L25.5 84.4743V83.4743Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 71.9743C6.64872 71.9743 1.5 77.123 1.5 83.4743C1.5 89.8256 6.64873 94.9743 13 94.9743C19.3513 94.9743 24.5 89.8256 24.5 83.4743C24.5 77.123 19.3513 71.9743 13 71.9743ZM13 70.9743C6.09644 70.9743 0.499998 76.5707 0.499999 83.4743C0.5 90.3779 6.09644 95.9743 13 95.9743C19.9036 95.9743 25.5 90.3779 25.5 83.4743C25.5 76.5707 19.9036 70.9743 13 70.9743Z"
    />
  </svg>
);
