import React from "react";

export const CyberSec = props => (
  <svg
    width="206"
    height="185"
    viewBox="0 0 206 185"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91 139.551C115.024 139.551 134.5 120.076 134.5 96.0514C134.5 72.027 115.024 52.5514 91 52.5514C66.9756 52.5514 47.5 72.027 47.5 96.0514C47.5 120.076 66.9756 139.551 91 139.551ZM91 140.551C115.577 140.551 135.5 120.628 135.5 96.0514C135.5 71.4747 115.577 51.5514 91 51.5514C66.4233 51.5514 46.5 71.4747 46.5 96.0514C46.5 120.628 66.4233 140.551 91 140.551Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3224 158.966C5.83141 163.457 5.83141 170.738 10.3224 175.229C14.8135 179.72 22.0949 179.72 26.5859 175.229C31.0769 170.738 31.0769 163.457 26.5859 158.966C22.0949 154.475 14.8135 154.475 10.3224 158.966ZM9.61533 158.259C4.73378 163.14 4.73378 171.055 9.61533 175.936C14.4969 180.818 22.4114 180.818 27.293 175.936C32.1746 171.055 32.1746 163.14 27.293 158.259C22.4114 153.377 14.4969 153.377 9.61533 158.259Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M101 84.8273H81C78.7909 84.8273 77 86.6181 77 88.8273V112.827H105V88.8273C105 86.6181 103.209 84.8273 101 84.8273ZM81 80.8273C76.5817 80.8273 73 84.409 73 88.8273V116.827H109V88.8273C109 84.409 105.418 80.8273 101 80.8273H81Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.3103 69.8617C88.168 69.8617 85.6206 72.4091 85.6206 75.5514V82.4743H81.6206V75.5514C81.6206 70.1999 85.9588 65.8617 91.3103 65.8617C96.6618 65.8617 101 70.1999 101 75.5514V82.4743H97V75.5514C97 72.4091 94.4526 69.8617 91.3103 69.8617Z"
    />
    <path d="M88.5635 102.056C88.5635 100.711 89.6543 99.6198 90.9999 99.6198C92.3456 99.6198 93.4364 100.711 93.4364 102.056V107.616H88.5635V102.056Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M181.25 98.0514H134.5V97.0514H181.25V98.0514Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M176.883 74.7045C173.508 78.0478 168.992 81.4305 163.616 82.7107L163.597 82.7152L134.203 87.3432L134.047 86.3554L163.403 81.7334C168.518 80.5101 172.871 77.2718 176.179 73.9941C179.308 70.8948 181.469 67.7946 182.436 66.409C182.491 66.3294 182.543 66.2555 182.59 66.1875L183.409 66.761C183.362 66.8293 183.31 66.9038 183.254 66.9843C182.283 68.3759 180.078 71.5397 176.883 74.7045Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3314 158.806L59.1777 125.96L59.8848 126.667L27.0385 159.513L26.3314 158.806Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91 25.0514L91 51.9743L90 51.9743L90 25.0514L91 25.0514Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.5 12.5514C102.5 6.20012 97.3513 1.05139 91 1.05139C84.6487 1.05139 79.5 6.20012 79.5 12.5514C79.5 18.9027 84.6487 24.0514 91 24.0514C97.3513 24.0514 102.5 18.9027 102.5 12.5514ZM103.5 12.5514C103.5 5.64783 97.9036 0.0513908 91 0.0513911C84.0964 0.0513914 78.5 5.64783 78.5 12.5514C78.5 19.455 84.0964 25.0514 91 25.0514C97.9036 25.0514 103.5 19.4549 103.5 12.5514Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M193.5 108.551C199.851 108.551 205 103.403 205 97.0514C205 90.7001 199.851 85.5514 193.5 85.5514C187.149 85.5514 182 90.7001 182 97.0514C182 103.403 187.149 108.551 193.5 108.551ZM193.5 109.551C200.404 109.551 206 103.955 206 97.0514C206 90.1478 200.404 84.5514 193.5 84.5514C186.596 84.5514 181 90.1478 181 97.0514C181 103.955 186.596 109.551 193.5 109.551Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M190.25 68.5514C196.601 68.5514 201.75 63.4027 201.75 57.0514C201.75 50.7001 196.601 45.5514 190.25 45.5514C183.899 45.5514 178.75 50.7001 178.75 57.0514C178.75 63.4027 183.899 68.5514 190.25 68.5514ZM190.25 69.5514C197.154 69.5514 202.75 63.955 202.75 57.0514C202.75 50.1478 197.154 44.5514 190.25 44.5514C183.346 44.5514 177.75 50.1478 177.75 57.0514C177.75 63.955 183.346 69.5514 190.25 69.5514Z"
    />
  </svg>
);
