import styled from "styled-components";

import { Box } from "../Grid";

const BreadcrumbWrapper = styled(Box)`
  list-style: none;
  display: flex;
`;

BreadcrumbWrapper.defaultProps = {
  p: 0,
  mb: 5,
};

export { BreadcrumbWrapper };
