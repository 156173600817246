import React from "react";

export const SmartDevice = props => (
  <svg
    width="166"
    height="167"
    viewBox="0 0 166 167"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M94.4426 65.1086H71.5571C67.6911 65.1086 64.5571 68.2426 64.5571 72.1086V94.9941C64.5571 98.8601 67.6911 101.994 71.5571 101.994H94.4426C98.3086 101.994 101.443 98.8601 101.443 94.9941V72.1086C101.443 68.2426 98.3086 65.1086 94.4426 65.1086ZM71.5571 64.1086C67.1389 64.1086 63.5571 67.6904 63.5571 72.1086V94.9941C63.5571 99.4124 67.1388 102.994 71.5571 102.994H94.4426C98.8609 102.994 102.443 99.4124 102.443 94.9941V72.1086C102.443 67.6904 98.8609 64.1086 94.4426 64.1086H71.5571Z"
    />
    <path d="M80.5635 73.0562C80.5635 71.7106 81.6543 70.6198 82.9999 70.6198C84.3456 70.6198 85.4364 71.7106 85.4364 73.0562V76.1795C85.4364 77.5251 84.3456 78.6159 82.9999 78.6159C81.6543 78.6159 80.5635 77.5251 80.5635 76.1795V73.0562Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M78.613 26.2588C78.7343 27.3567 77.9426 28.345 76.8447 28.4662C49.1332 31.527 27.5771 55.0232 27.5771 83.5514C27.5771 112.08 49.1332 135.576 76.8447 138.636C77.9426 138.758 78.7343 139.746 78.613 140.844C78.4918 141.942 77.5035 142.734 76.4056 142.612C46.6896 139.33 23.5771 114.141 23.5771 83.5514C23.5771 52.9615 46.6896 27.7725 76.4056 24.4904C77.5035 24.3692 78.4918 25.1609 78.613 26.2588ZM87.3872 26.2588C87.5085 25.1609 88.4968 24.3692 89.5947 24.4904C119.311 27.7726 142.423 52.9615 142.423 83.5514C142.423 114.141 119.311 139.33 89.5947 142.612C88.4968 142.734 87.5085 141.942 87.3872 140.844C87.2659 139.746 88.0577 138.758 89.1556 138.636C116.867 135.576 138.423 112.08 138.423 83.5514C138.423 55.0232 116.867 31.527 89.1556 28.4662C88.0577 28.345 87.2659 27.3567 87.3872 26.2588Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.473 73.9257C110.192 76.7271 111.183 80.0235 111.183 83.5514C111.183 87.0793 110.192 90.3756 108.473 93.177L109.063 93.7669C110.921 90.8066 111.995 87.3045 111.995 83.5514C111.995 79.7982 110.921 76.2961 109.063 73.3359L108.473 73.9257ZM113.706 98.41L114.288 98.9922C117.411 94.6448 119.25 89.3129 119.25 83.5514C119.25 77.7899 117.411 72.4579 114.288 68.1105L113.706 68.6927C116.686 72.8869 118.438 78.0145 118.438 83.5514C118.438 89.0882 116.686 94.2158 113.706 98.41Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.5273 93.1771C55.808 90.3757 54.8166 87.0793 54.8166 83.5514C54.8166 80.0235 55.808 76.7271 57.5273 73.9257L56.9375 73.3358C55.0791 76.2961 54.0045 79.7982 54.0045 83.5514C54.0045 87.3045 55.0791 90.8067 56.9375 93.7669L57.5273 93.1771ZM52.2944 68.6927L51.7122 68.1105C48.5891 72.4579 46.75 77.7899 46.7501 83.5514C46.7501 89.3129 48.5891 94.6448 51.7122 98.9922L52.2944 98.41C49.3143 94.2159 47.5622 89.0882 47.5622 83.5514C47.5622 78.0145 49.3143 72.8869 52.2944 68.6927Z"
    />
  </svg>
);
